import axios from '@axios'

export default {
  namespaced: true,
  state: {
    condition:{
    },
  },
  getters: {
    getCondition(state){
      return state.condition
    }
  },
  mutations: {
    updateCondition(state,condition){
      state.condition = condition
    }
  },
  actions: {
    initStepTwoPage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseinvoicestatement/initStepTwoPage', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveSecond(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/purchaseinvoicestatement/saveSecond', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
