<template>
  <div>
    <b-card
        no-body
        class="mb-0 list-table"
    >
      <div>
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-card>
              <b-row>
                <!--筛选条件-->
                <b-col
                    cols="12"
                    md="3"
                >
                  <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="发票号码:"
                      label-for="invoice_no"
                      label-size="sm"
                  >
                    <b-form-input
                        id="name"
                        v-model="condition.invoice_no"
                        class="d-inline-block mr-1"
                        size="sm"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="12"
                    md="3"
                >
                  <b-button
                      variant="outline-primary"
                      type="submit"
                      class="mr-1"
                  >
                    <span class="align-right">查询</span>
                  </b-button>

                  <b-button
                      variant="outline-primary"
                      type="reset"
                  >
                    <span class="align-right">重置</span>
                  </b-button>

                </b-col>
              </b-row>
            </b-card>
          </b-form>
      </div>
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="invoice_id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >

        <template v-slot:head(#)="data">
          <b-form-checkbox v-model="allchecked" @change="toggleAll" :indeterminate="indeterminate">
          </b-form-checkbox>
        </template>

        <template v-slot:cell(#)="row">
          <b-form-checkbox @change="checkboxClick(row.item,$event)" v-model="isCheckedObj['index'+row.item.invoice_id]">
          </b-form-checkbox>
        </template>

        <template #cell(invoice_type)="data">
          {{ getCodeLabel('invoice_type', data.item.invoice_type) }}
        </template>
        <!-- Columns -->
        <template #cell(buyer_name)="data">
<!--          <b-form-checkbox name="check-button"
                           button-variant="default"
                           size="sm"
                           button>
          </b-form-checkbox>-->
          {{data.item.buyer_name}}
        </template>
        <template #cell(buyer_idno)="data">
          {{ data.item.buyer_idno }}
        </template>
        <template #cell(sell_name)="data">
          {{ data.item.sell_name }}
        </template>
        <template #cell(sell_idno)="data">
          {{ data.item.sell_idno }}
        </template>
        <template #cell(total)="data">
          {{ data.item.total }}
        </template>
        <template #cell(make_date_val)="data">
          {{ toDate(data.item.make_date_val) }}
        </template>
        <template #cell(invoice_code)="data">
          {{ data.item.invoice_code }}
        </template>
        <template #cell(invoice_no)="data">
          {{ data.item.invoice_no }}
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import Ripple from "vue-ripple-directive";
import {ref, onUnmounted, reactive, onMounted, toRefs} from '@vue/composition-api'
import { avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions,toDate } from '@core/utils/filter'
import invoiceSelectList from './invoiceSelectList'
import invoiceSelectStore from "@/views/apps/purchaseinvoicestatement/invoiceSelectStore";

export default {
  components: {
    BCard,
    BModal,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      indeterminate: false,
      allchecked: false,
      isCheckedObj: {},
      selected: [],
    }
  },
  watch: {
    start:'refetchData',
    limit:'refetchData'
  },
  props:{
    buyer_sbc_company_id: {
      type: Number
    },
    sell_sbc_company_id: {
      type: Number
    },
    selected_no: {
      type: Array,
      default: () => []
    },
  },

  methods: {
    getSelected: function () {
      return this.selected
    },
    searchByCondition(){
      this.refetchData()
    },
    resetCondition() {
      this.state.condition = {}
      store.commit('invoiceSelectList/updateCondition', this.state.condition)
      this.refetchData()
    },
    // 清空选择框
    clearCheckBox() {
      this.allchecked = false;
      this.indeterminate = false
      this.selected = []
      this.$forceUpdate()
    },
    refetchData(){
      this.$refs.refListTable.refresh()
    },
    // 点击全选按钮
    toggleAll(checked) {
      const arrSelected = [];
      if(this.selected&&this.selected.length>0){
        this.selected.forEach(element => {
          arrSelected.push(element.invoice_id)
        });
      }
      this.table.forEach(element => {
        this.isCheckedObj['index' + element.invoice_id] = checked;
        let indexOf = arrSelected.indexOf(element.invoice_id);
        if(indexOf >-1&&!checked){
          arrSelected.splice(indexOf, 1);
          this.selected.splice(indexOf, 1);
        }else if(indexOf < 0 && checked){
          arrSelected.push(element.invoice_id);
          this.selected.push(element);
        }
      });
      this.$forceUpdate()
    },
    // 复选框点击选中与取消
    checkboxClick(item,checked) {
      //全选框
      let allselect = true;
      this.table.forEach(element => {
        if (this.isCheckedObj['index' + element.invoice_id] !== true) {
          allselect = false;
        }
      });
      this.allchecked = allselect;
      if(checked){
        this.selected.push(item)
      }else{
        for(let i=0;i<this.selected.length;i++){
          if(this.selected[i].invoice_id == item.invoice_id){
            this.selected.splice(i, 1);
          }
        }
      }
      this.$forceUpdate()
    },
  },
  directives: {
    Ripple,
  },
  setup(props) {

    const state = reactive({
      condition: {},
    })

    // Register module
    if (!store.hasModule('invoiceSelectList')) store.registerModule('invoiceSelectList', invoiceSelectStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('invoiceSelectList')) store.unregisterModule('invoiceSelectList')
    })

    onMounted(() => {
      state.condition = store.getters['invoiceSelectList/getCondition']
    })

    const {
      searchList,
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
    } = invoiceSelectList(
        {sell_sbc_company_id:props.sell_sbc_company_id,buyer_sbc_company_id:props.buyer_sbc_company_id
          ,selectedInvoiceNo:props.selected_no}
    )

    return {
      ...toRefs(state),
      state,
      searchList,
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,

      // Filter
      avatarText,
      getCodeLabel,
      getCode,
      getCodeColor,
      getCodeOptions,
      toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
