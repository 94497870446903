import {ref, watch, computed, reactive} from '@vue/composition-api'
import store from '@/store'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function purchaseInvoiceStatementStepTwo(config) {

  // Table Handlers
  const invoiceTableColumns = [
    { key: 'no', label: '序号'},
    { key: 'invoice_type', label: '发票类型'},
    { key: 'invoice_code', label: '发票代码'},
    { key: 'invoice_no', label: '发票号码'},
    { key: 'buyer_name', label: '购买方名称'},
    { key: 'sell_name', label: '销售方名称'},
    { key: 'sell_idno', label: '销售方纳税识别号'},
    { key: 'sell_bankinfo', label: '销售方开户行账号'},
    { key: 'amount', label: '金额'},
    { key: 'tax_amount', label: '税额'},
    { key: 'total', label: '价税合计'},
    { key: 'make_date_val', label: '开票日期'},
    { key: 'attachments', label: '附件'},
    { key: 'actions', label: '操作'},
  ]
  // Table Handlers
  const purchaseOrderTableColumns = [
    { key: 'no', label: '序号'},
    { key: 'order_no', label: '订单编号'},
    { key: 'purchase_total', label: '订单金额'},
    { key: 'advance_payment_amount', label: '预付款金额'},
    { key: 'ready_written_off_amount', label: '待核销金额'},
    { key: 'payable_amount', label: '本次应付金额'},
    { key: 'written_off_pay_amount', label: '本次核销预付金额'},
    { key: 'apply_amount', label: '本次申请付款金额'},
    { key: 'actions', label: '操作'},
  ]
  // Table Handlers
  const accountTableColumns = [
    // { key: 'no', label: '序号'},
    { key: 'payee_type', label: '账户类型'},
    { key: 'payee_name', label: '账户名称'},
    { key: 'payee_account', label: '银行账号'},
    { key: 'payee_bank_name', label: '收款行及支行名称'},
    { key: 'bank_address', label: '银行地址'},
    { key: 'is_oversea_payee', label: '是否境外'},
    { key: 'swift_code', label: 'swift code'},
    { key: 'remark', label: '备注'},
    { key: 'actions', label: '操作'},
  ]
  return {
    invoiceTableColumns,
    purchaseOrderTableColumns,
    accountTableColumns,

  }
}
