import {ref, watch, computed, reactive} from '@vue/composition-api'
import store from '@/store'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function accountSelectList(config) {
  const toast = useToast()
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'payee_type', label: '账户类型'},
    { key: 'payee_name', label: '账户名称'},
    { key: 'payee_account', label: '银行账号'},
    { key: 'payee_bank_name', label: '收款行及支行名称'},
    { key: 'bank_address', label: '银行地址'},
    { key: 'is_oversea_payee', label: '是否境外'},
    { key: 'swift_code', label: 'swift code'},
    { key: 'remark', label: '备注'},
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const orderBy = ref(" ")
  const isSortDirDesc = ref(false)
  const searchKey = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit,searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = {...store.getters['accountSelectList/getCondition']}
    store
        .dispatch('accountSelectList/search', {
          search_key: searchKey.value,
          supplier_id: config.supplier_id,

          start: start.value,
          limit: limit.value,
          order_by: orderBy.value,
          order_desc: isSortDirDesc.value === true ? 'desc':'',
        })
        .then(response => {
          const list = response.data.data.ext.list
          // listTotals.value = data.ext.totals todo
          callback(list)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '账号列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    refetchData,

  }
}
