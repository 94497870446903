<template>
  <div>
    <b-card>
      <div>
        <b-row>
          <b-col
              cols="12"
              class="mt-50"
              style="margin-bottom: 20px"
          >
            <b-button
                v-if="!readonlyFlag"
                variant="outline-success"
                class="mr-1"
                @click="save(2)"
            >
              提交
            </b-button>
            <b-button
                v-if="!readonlyFlag"
                variant="outline-success"
                class="mr-1"
                @click="save(1)"
            >
              保存
            </b-button>
            <b-button
                variant="outline-secondary"
                class="mr-1"
                @click="cancel()"
            >
              取消
            </b-button>

          </b-col>
          <b-col
              cols="12"
              class="mt-50"
              style="margin-bottom: 20px"
          >

            <b-button
                variant="link"
                class="mr-1"
                @click="showFirst()"
            >
              采购入库
            </b-button>
<!--            <b-button
                variant="link"
                class="mr-1"
                @click="save(1)"
            >
              发票付款
            </b-button>-->
          </b-col>
          <b-col md="12">
            <div class="inner-card-title">发票信息</div>
          </b-col>

          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label="发票选择:"
                label-for="selectInvoice"
                label-size="sm"
            >
              <b-button
                  v-if="!readonlyFlag"
                  id="selectInvoice"
                  variant="outline-secondary"
                  v-b-modal.modal-select-invoice
                  @click="selectInvoice()"
              >
                <feather-icon
                    icon="PlusIcon"
                />
              </b-button>
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label="价税合计:"
                label-for="total"
                label-size="sm"
            >
              <b-form-input
                  id="total"
                  v-model="total"
                  class="d-inline-block mr-1"
                  size="sm"
                  :readonly="true"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label="税额合计:"
                label-for="totalTaxAmount"
                label-size="sm"
            >
              <b-form-input
                  id="totalTaxAmount"
                  v-model="totalTaxAmount"
                  class="d-inline-block mr-1"
                  size="sm"
                  :readonly="true"
              />
            </b-form-group>
          </b-col>
<!--          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label="销售方纳税识别号:"
                label-for="amount3"
                label-size="sm"
            >
              <b-form-input
                  id="amount3"
                  v-model="amount3"
                  class="d-inline-block mr-1"
                  size="sm"
                  :readonly="true"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label="销售方开户行账号:"
                label-for="amount4"
                label-size="sm"
            >
              <b-form-input
                  id="amount4"
                  v-model="amount4"
                  class="d-inline-block mr-1"
                  size="sm"
                  :readonly="true"
              />
            </b-form-group>
          </b-col>-->

          <b-table
              ref="invoiceTable"
              class="position-relative"
              :items="invoiceSearchList"
              responsive
              show-empty
              empty-text="未找到记录"
              :fields="invoiceTableColumns"
              primary-key="order_id"
              v-model="invoiceDataList"
          >
            <template #row-details="row">
              <b-card>
                <div>
                  <b-table striped hover :items="row.item.ext.items" :fields="invoiceItemfields">
                    <template #cell(no)="data">
                      {{data.item.ext.no}}
                    </template>
                    <template #cell(service_name)="data">
                      {{data.item.service_name}}
                      {{  data.item.ext.product_id}}
                      <span class="align-middle ml-50" v-b-modal.modal-product @click="relateProduct(data.item)">
                        <feather-icon icon="CommandIcon" style="margin-top: 7px"/>
                      </span>
                    </template>
                    <template #cell(specification)="data">
                      {{data.item.specification}}
                    </template>
                    <template #cell(service_unit)="data">
                      {{data.item.service_unit}}
                    </template>
                    <template #cell(code)="data">
                      {{data.item.ext.code}}
                    </template>
                    <template #cell(one_code)="data">
                      {{data.item.ext.one_code}}
                    </template>
                    <template #cell(service_qty)="data">
                      {{data.item.service_qty}}
                    </template>
                    <template #cell(price)="data">
                      {{data.item.price}}
                    </template>
                    <template #cell(total)="data">
                      {{data.item.ext.total}}
                    </template>
                    <template #cell(rate)="data">
                      {{data.item.rate}}
                    </template>
                    <template #cell(tax_amount)="data">
                      {{data.item.tax_amount}}
                    </template>

                  </b-table>
                </div>
              </b-card>
            </template>
            <template #cell(no)="data">
              {{ data.item.no }}
              <b-form-checkbox v-model="data.detailsShowing"
                               name="check-button"
                               @change="data.toggleDetails"
                               button-variant="default"
                               size="sm"
                               button>
                <feather-icon
                    icon="MinusSquareIcon"
                    size="15"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==true"
                />
                <feather-icon
                    icon="PlusSquareIcon"
                    size="16"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==false"
                />
              </b-form-checkbox>
            </template>
            <template #cell(invoice_type)="data">
              {{ getCodeLabel('invoice_type', data.item.invoice_type) }}
            </template>
            <template #cell(invoice_code)="data">
              {{ data.item.invoice_code }}
            </template>
            <template #cell(invoice_no)="data">
              {{ data.item.invoice_no }}
            </template>
            <template #cell(buyer_name)="data">
              {{ data.item.buyer_name }}
            </template>
            <template #cell(sell_name)="data">
              {{ data.item.sell_name }}
            </template>
            <template #cell(sell_idno)="data">
              {{ data.item.sell_idno }}
            </template>
            <template #cell(sell_bankinfo)="data">
              {{ data.item.sell_bankinfo }}
            </template>
            <template #cell(amount)="data">
              {{ data.item.amount }}
            </template>
            <template #cell(tax_amount)="data">
              {{ data.item.tax_amount }}
            </template>
            <template #cell(total)="data">
              {{ data.item.total }}
            </template>
            <template #cell(make_date_val)="data">
              {{ toDate(data.item.make_date_val) }}
            </template>
            <template #cell(attachments)="data">
              <div v-show="data.item.attachments !== undefined&&data.item.attachments !== '' ">
                <feather-icon icon="EyeIcon" @click="showAttachments(data.item.attachments)"/>
              </div>
            </template>
            <template #cell(actions)="data">
              <span v-if="!readonlyFlag" class="align-middle ml-50" @click="deleteInvoice(data)"><feather-icon icon="DeleteIcon"/></span>
            </template>
          </b-table>

          <b-col md="12">
            <div class="inner-card-title">差异信息</div>
          </b-col>
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label="税额差异:"
                label-for="taxAmountDif"
                label-size="sm"
            >
              <b-form-input
                  id="taxAmountDif"
                  v-model="taxAmountDif"
                  class="d-inline-block mr-1"
                  size="sm"
                  :readonly="true"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label="价税合计差异金额:"
                label-for="totalDif"
                label-size="sm"
            >
              <b-form-input
                  id="totalDif"
                  v-model="totalDif"
                  class="d-inline-block mr-1"
                  size="sm"
                  :readonly="true"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label="差异原因:"
                label-for="memo_difference"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="memo_difference"
                  v-model="memo_difference"
                  class="d-inline-block mr-1"
                  size="sm"
                  :readonly="readonlyFlag"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="4"
              style="margin-left: 58px;margin-top: -10px;color: #c5c5c7"
          >
            <span style="font-size: 1px;">税额差异金额=入库单税额 - 退货税额 - 发票税额</span>
          </b-col>
          <b-col
              cols="12"
              md="4"
              style="margin-left: -49px;margin-top: -10px;color: #c5c5c7"
          >
            <span style="font-size: 1px;">价税合计差异金额=入库单价税合计 - 退货价税合计 -发票价税合计</span>
          </b-col>


          <b-col md="12">
            <div class="inner-card-title">付款核销</div>
          </b-col>
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label="结算金额:"
                label-for="settlementAmount"
                label-size="sm"
            >
              <b-form-input
                  id="settlementAmount"
                  v-model="settlementAmount"
                  class="d-inline-block mr-1"
                  size="sm"
                  :readonly="true"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label="本次核销预付款:"
                label-for="totalVerifyAmount"
                label-size="sm"
            >
              <b-form-input
                  id="totalVerifyAmount"
                  v-model="totalVerifyAmount"
                  class="d-inline-block mr-1"
                  size="sm"
                  :readonly="true"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label="本次申请付款金额:"
                label-for="totalApplyAmount"
                label-size="sm"
            >
              <b-form-input
                  id="totalApplyAmount"
                  v-model="totalApplyAmount"
                  class="d-inline-block mr-1"
                  size="sm"
                  :readonly="true"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="4"
              style="margin-left: 58px;margin-top: -10px;color: #c5c5c7"
          >
            <span style="font-size: 1px;">结算金额 = 入库价税合计 - 退货价税合计 - 返利抵扣 - 扣款抵扣</span>
          </b-col>
          <b-col
              cols="12"
              md="6"
          >
          </b-col>

          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label="最晚付款日期:"
                label-for="last_pay_date"
                label-size="sm"
                class="mb-1 required"
            >
              <flat-pickr
                  v-model="last_pay_date"
                  class="form-control"
                  placeholder="请选择最晚付款日期"
                  :disabled="readonlyFlag"
              />
            </b-form-group>
          </b-col>

          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label="付款事由:"
                label-for="pay_reason"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="pay_reason"
                  v-model="pay_reason"
                  class="d-inline-block mr-1"
                  size="sm"
                  :readonly="readonlyFlag"
              />
            </b-form-group>
          </b-col>


          <b-table
              ref="purchaseOrderTable"
              class="position-relative"
              :items="purchaseOrderSearchList"
              responsive
              show-empty
              empty-text="未找到记录"
              :fields="purchaseOrderTableColumns"
              primary-key="order_id"
              v-model="purchaseOrderDataList"
          >
            <template #row-details="row">
              <b-card>
                <div>
                  <b-table striped hover :items="row.item.ext.payList" :fields="prePayItemfields">
                    <template #cell(order_no)="data">
                      {{data.item.order_no}}
                    </template>
                    <template #cell(purchase_order_no)="data">
                      {{data.item.purchase_order_no}}
                    </template>
                    <template #cell(total_payed_oa)="data">
                      {{data.item.total_payed_oa}}
                    </template>
                    <template #cell(un_verify_amount)="data">
                      {{data.item.ext.un_verify_amount}}
                    </template>
                    <template #cell(new_verify_amount)="data">
                      <b-form-input
                          size="sm"
                          v-model="data.item.ext.new_verify_amount"
                          style="width: 80px"
                          type="number"
                          @change="checkVerifyAmount(data.item)"
                      />
                    </template>
                    <template #cell(verify_amount_true)="data">
                      {{ data.item.verify_amount_true||0 }}
                    </template>
                    <template #cell(create_time)="data">
                      {{ toDate(data.item.create_time) }}
                    </template>
                    <template #cell(applier_id)="data">
                      {{getCodeLabel('user', data.item.applier_id)}}
                    </template>
                    <template #cell(actions)="data">
                      <span v-if="!readonlyFlag" class="align-middle ml-50" @click="deletePayment(data.item)"><feather-icon icon="DeleteIcon"/></span>
                    </template>
                  </b-table>
                </div>
              </b-card>
            </template>

            <template #cell(no)="data">
              {{ data.item.ext.no }}
              <b-form-checkbox v-model="data.detailsShowing"
                               name="check-button"
                               @change="data.toggleDetails"
                               button-variant="default"
                               size="sm"
                               button>
                <feather-icon
                    icon="MinusSquareIcon"
                    size="15"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==true"
                />
                <feather-icon
                    icon="PlusSquareIcon"
                    size="16"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==false"
                />
              </b-form-checkbox>
            </template>
            <template #cell(order_no)="data">
              {{data.item.order_no}}
            </template>
            <template #cell(purchase_total)="data">
              {{data.item.purchase_total}}
            </template>
            <template #cell(advance_payment_amount)="data">
              {{data.item.ext.advance_payment_amount}}
            </template>
            <template #cell(ready_written_off_amount)="data">
              {{data.item.ext.ready_written_off_amount}}
            </template>
            <template #cell(payable_amount)="data">
              {{data.item.ext.payable_amount}}
            </template>
            <template #cell(written_off_pay_amount)="data">
              {{data.item.ext.written_off_pay_amount}}
            </template>
            <template #cell(apply_amount)="data">
              {{data.item.ext.apply_amount}}
            </template>


            <template #cell(actions)="data">
              <a href="#" class="align-middle ml-50" v-if="!readonlyFlag"
                 v-b-modal.modal-select-payment @click="addPayment(data)">添加预付核销</a>
            </template>
          </b-table>

          <b-col md="12">
            <div class="inner-card-title">收款方</div>
          </b-col>
          <b-col
              cols="12" md="4">
            <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label="供应商:"
                label-for="supplier_name"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="supplier_name"
                  size="sm"
                  v-model="supplier_name"
                  :readonly="true"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label="收款账号是否修改:"
                label-for="is_same"
                label-size="sm"
                class="mb-1 required"
            >
              <v-select
                  id="is_same"
                  :options="getCodeOptions('yesno')"
                  :clearable="false"
                  v-model="is_same"
                  class="select-size-sm"
                  :reduce="option => option.value"
                  :disabled="readonlyFlag"
              />
            </b-form-group>
          </b-col>

          <b-table
              ref="accountTable"
              class="position-relative"
              :items="accountSearchList"
              responsive
              show-empty
              empty-text="未找到记录"
              :fields="accountTableColumns"
              primary-key="order_id"
              v-model="accountDataList"
          >
<!--            <template #cell(no)="data">
              {{ data.item.no }}
            </template>-->
            <template #cell(payee_type)="data">
              {{ getCodeLabel('bank_account_type', data.item.payee_type) }}
            </template>
            <template #cell(payee_name)="data">
              {{ data.item.payee_name }}
            </template>
            <template #cell(payee_account)="data">
              {{ data.item.payee_account }}
            </template>
            <template #cell(payee_bank_name)="data">
              {{ data.item.payee_bank_name }}
            </template>
            <template #cell(bank_address)="data">
              {{ data.item.bank_address }}
            </template>
            <template #cell(is_oversea_payee)="data">
              {{ getCodeLabel('yesno', data.item.is_oversea_payee) }}
            </template>
            <template #cell(swift_code)="data">
              {{ data.item.swift_code }}
            </template>
            <template #cell(remark)="data">
              {{ data.item.remark }}
            </template>
            <template #cell(actions)="data">
<!--              todo-->
              <a href="#" v-if="!readonlyFlag" class="align-middle ml-50" v-b-modal.modal-account @click="changeAccount(data)">切换账号</a>
            </template>
          </b-table>

          <b-col md="12" >
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="附件"
                label-for="attachments"
                label-size="sm"
                class="mb-1"
                style="margin-left: -55px"
            >
              <attachment-upload v-if="purchaseInvoiceStatement.loaded":theme="'files'"
                                 :attachment_id="'attachments'"
                                 :id="purchaseInvoiceStatement.attachments"
                                 :object_type="'purchaseInvoiceStatement'"
                                 :object_id="purchaseInvoiceStatement.statement_id"
                                 @change="onUploaded"
                                 style="width: 200px;float: left"
                                 :readonly="readonlyFlag"
              />
              <div style="margin-top: 17px"><span style="margin-left: 10px;font-size: 2px;">温馨提示：附件包含：采购订单（盖章版）、毛利测算表、提单、验货报告（结合结算方式）、以销定采订单（如果销售端需预付款，需要提供到账水单）</span></div>

            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <!--/ form -->
      <b-modal
          id="loadingModal"
          centered
          hide-header
          no-close-on-backdrop
          no-close-on-esc
          hide-footer
          ref="loadingModal"
      >
        <p class="my-4">正在处理，请勿关闭浏览器!</p>
      </b-modal>

      <b-modal
          id="modal-attachments"
          centered
          ok-only
          ok-title="确认"
          size="lg"
          title="查看附件"
          ref="attachModal"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="附件"
                label-for="attachments_ids"
                label-size="sm"
                class="mb-1"
            >
              <attachment-upload :theme="'files'"
                                 :attachment_id="'attachments_ch'"
                                 :id="attachmentsStr"
                                 :object_type="'purchaseInvoice'"
                                 :object_id="0"
                                 :readonly="true"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>

      <b-modal
          v-if="showInvoice"
          id="modal-select-invoice"
          ok-only
          ok-title="确认"
          @ok="onSelectInvoice"
          cancel-title="取消"
          centered
          size="xl"
          title="选择发票"
      >
        <invoice-select-list
            ref="invoiceSelect"
            :selected_no="selectedInvoiceNo"
            :buyer_sbc_company_id="buyer_sbc_company_id"
            :sell_sbc_company_id="sell_sbc_company_id"
        >
        </invoice-select-list>
      </b-modal>
      <b-modal
          v-if="showPayment"
          id="modal-select-payment"
          ok-only
          ok-title="确认"
          @ok="onSelectPayment"
          cancel-title="取消"
          centered
          size="xl"
          title="选择付款单"
      >
        <payment-select-list
            ref="paymentSelect"
            :selected_no="selectedPaymentNo"
            :purchase_order_id="purchase_order_id"
            :statement_id="String(statement_id)"
        >
        </payment-select-list>
      </b-modal>

      <b-modal
          v-if="showProduct"
          id="modal-product"
          ok-only
          ok-title="确认"
          @ok="onSelectProduct"
          cancel-title="取消"
          centered
          size="lg"
          title="选择产品"
          ref="singleModal"
      >
        <relate-product-list
            ref="relateProductSelect"
            :name="service_name"
            :statement_id="statement_id"
        >
        </relate-product-list>
      </b-modal>

      <b-modal
          v-if="showAccount"
          id="modal-account"
          ok-only
          ok-title="确认"
          @ok="onSelectAccount"
          cancel-title="取消"
          centered
          size="lg"
          title="选择银行账号"
          ref="singleModal"
      >
        <account-select-list
            ref="relateAccountSelect"
            :supplier_id="sell_sbc_company_id"
        >
        </account-select-list>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem,BFormRadio, BPagination, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import Ripple from "vue-ripple-directive";
import {ref, onUnmounted, reactive, onMounted, toRefs} from '@vue/composition-api'
import { toDate,avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions,isEmpty } from '@core/utils/filter'
import purchaseInvoiceStatementStepTwoStore from "@/views/apps/purchaseinvoicestatement/purchaseInvoiceStatementStepTwoStore";
import purchaseInvoiceStatementStepTwo from './purchaseInvoiceStatementStepTwo.js'
import axios from "@/libs/axios";
import XyInputButton from "@/views/components/xy/XyInputButton";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import {second} from "@core/utils/dayjs/utils";
import {useToast} from "vue-toastification/composition";
import InvoiceSelectList from "@/views/apps/purchaseinvoicestatement/InvoiceSelectList.vue";
import PaymentSelectList from "@/views/apps/purchaseinvoicestatement/PaymentSelectList.vue";
import RelateProductList from "@/views/apps/purchaseinvoicestatement/RelateProductList.vue";
import AccountSelectList from "@/views/apps/purchaseinvoicestatement/AccountSelectList.vue";

export default {
  components: {
    BCard,
    BModal,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormRadio,
    BPagination,
    vSelect,
    XyInputButton,
    AttachmentUpload,
    InvoiceSelectList,
    PaymentSelectList,
    RelateProductList,
    AccountSelectList,
  },
  data() {
    return {
      readonlyFlag:false,
      status_one:null,
      statement_id:null,
      invoice_id_old:null,
      purchaseInvoiceStatement:ref({}),
      total:null,
      totalTaxAmount:null,
      /*amount3:null,
      amount4:null,*/
      taxAmountDif:null,
      totalDif:null,
      settlementAmount:null,
      memo_difference:null,
      totalVerifyAmount:null,
      totalApplyAmount:null,
      last_pay_date:null,
      pay_reason:null,
      supplier_name:'',
      is_same:'0',
      rangeConfig: {
        minDate: 'today',
      },

      showInvoice: false,
      showPayment: false,
      showAccount: false,
      service_name: '',
      invoice_id: null,
      invoice_item_id: null,
      showProduct: false,
      purchase_order_id: null,
      attachmentsStr: "",
      invoiceSearchList: [],
      selectedInvoiceNo:[],
      selectedPaymentNo:[],
      sell_sbc_company_id:null,
      buyer_sbc_company_id:null,
      invoiceDataList: [],
      paymentDataList: [],
      purchaseOrderSearchList: [],
      purchaseOrderDataList: [],
      accountSearchList: [],
      accountDataList: [],
      select_invoice_type: null,

      invoiceItemfields: [
        {
          key: 'no',
          label: '序号',
        },
        {
          key: 'service_name',
          label: '货品或应税劳务、服务名称',
        },
        {
          key: 'specification',
          label: '规格型号',
        },
        {
          key: 'service_unit',
          label: '单位',
        },
        {
          key: 'code',
          label: '69码',
        },
        {
          key: 'one_code',
          label: '集团条码',
        },
        {
          key: 'service_qty',
          label: '数量',
        },
        {
          key: 'price',
          label: '单价',
        },
        {
          key: 'total',
          label: '含税金额',
        },
        {
          key: 'rate',
          label: '税率',
        },
        {
          key: 'tax_amount',
          label: '税额',
        },
      ],
      prePayItemfields: [
        {
          key: 'order_no',
          label: '预付编号',
        },
        {
          key: 'purchase_order_no',
          label: '订单编号',
        },
        {
          key: 'total_payed_oa',
          label: '预付款金额',
        },
        {
          key: 'un_verify_amount',
          label: '待核销金额',
        },
        {
          key: 'new_verify_amount',
          label: '本次核销金额(申请)',
        },
        {
          key: 'verify_amount_true',
          label: '本次核销金额(实际)',
        },
        {
          key: 'create_time',
          label: '预付申请日期',
        },
        {
          key: 'applier_id',
          label: '申请人',
        },
        {
          key: 'actions',
          label: '操作',
        },
      ],
    }
  },
  methods: {
    showFirst(){
      this.$router.push({ name: 'apps-purchase-invoice-statement-edit',query: { id: this.statement_id }});
    },
    cancel(){
      this.$router.push({ name: 'apps-purchase-invoice-statement-list'});
    },
    save(status_one){
      if(isEmpty(this.memo_difference)){
        alert("请填写差异原因")
        return
      }
      if(isEmpty(this.last_pay_date)){
        alert("请填写最晚付款日期")
        return
      }
      if(this.last_pay_date<toDate(second(new Date()))){
        alert("最晚付款日期不可小于当前日期")
        return
      }
      if(isEmpty(this.pay_reason)){
        alert("请填写付款事由")
        return
      }
      if(this.invoiceDataList.length == 0){
        alert("请添加发票")
        return
      }
      //验证发票明细是否关联产品
      let unRelationPro = []
      for(let i=0;i<this.invoiceDataList.length;i++){
        for(let j=0;j<this.invoiceDataList[i].ext.items.length;j++){
          if(isEmpty(this.invoiceDataList[i].ext.items[j].ext.product_id) && unRelationPro.indexOf(this.invoiceDataList[i].invoice_no)<0){
            unRelationPro.push(this.invoiceDataList[i].invoice_no)
          }
        }
      }
      if(unRelationPro.length>0){
        alert("发票号："+unRelationPro.join(",")+"有明细未关联产品")
        return
      }
      //todo chenc
      /*if(!this.accountDataList||this.accountDataList.length == 0||
          (isEmpty(this.accountDataList[0].payee_type)
          &&isEmpty(this.accountDataList[0].payee_name)
          &&isEmpty(this.accountDataList[0].payee_account)
          &&isEmpty(this.accountDataList[0].payee_bank_name)
          &&isEmpty(this.accountDataList[0].bank_address)
          &&isEmpty(this.accountDataList[0].is_oversea_payee)
          &&isEmpty(this.accountDataList[0].swift_code)
          &&isEmpty(this.accountDataList[0].remark)
          )){
        alert("请添加银行账号信息")
        return
      }*/
      let arr = [];
      for(let i=0;i<this.purchaseOrderDataList.length;i++){
        if(!this.purchaseOrderDataList[i].ext.payList || this.purchaseOrderDataList[i].ext.payList.length == 0 ){
          arr.push(this.purchaseOrderDataList[i].order_no)
        }else{
          let verifyTotal = 0;
          let payList = this.purchaseOrderDataList[i].ext.payList;
          for(let j=0;j<payList.length;j++){
            if(payList[j].ext.new_verify_amount == undefined || payList[j].ext.new_verify_amount === ''){
              alert("采购单："+this.purchaseOrderDataList[i].order_no+"，预付编号："+payList[j].order_no+"未填本次核销预付金额")
              return;
            }
            verifyTotal = this.accAdd(verifyTotal,payList[j].ext.new_verify_amount)
          }
          if(parseFloat(verifyTotal)>this.purchaseOrderDataList[i].ext.payable_amount){
            alert("采购单："+this.purchaseOrderDataList[i].order_no+"本次核销金额之和大于本次应付金额")
            return;
          }
        }
      }
      /*if(arr.length>0){
        alert("采购单："+arr.join(",")+"未添加预付核销")
        return;
      }*/
      let obj = {}
      obj.statementId = this.statement_id
      obj.taxAmountDifference = this.taxAmountDif
      obj.totalAmountDifference = this.totalDif
      obj.memoDifference = this.memo_difference
      obj.amount = this.settlementAmount
      obj.verifyPrePay = this.totalVerifyAmount
      obj.applyPay = this.totalApplyAmount
      obj.lastPayDate = second(this.last_pay_date)
      obj.payReason = this.pay_reason
      obj.isSame = this.is_same
      obj.status_one = status_one
      obj.attachments = this.purchaseInvoiceStatement.attachments
      obj.invoiceJsonStr = JSON.stringify(this.invoiceDataList)
      obj.purchaseOrderJsonStr = JSON.stringify(this.purchaseOrderDataList)
      obj.accountJsonStr = JSON.stringify(this.accountDataList)
      this.$refs['loadingModal'].show()
      store.dispatch('purchaseInvoiceStatementStepTwo/saveSecond', obj).then(res => {
        this.$refs['loadingModal'].hide()
        if (res.data.code==0){
          this.$router.push({ name: 'apps-purchase-invoice-statement-list'});
        }else {
          alert(res.data.data);
        }
      })
    },
    changeAccount(data){
      this.showAccount = true
    },
    onSelectAccount(){
      let selected = this.$refs.relateAccountSelect.getSelected();
      if(selected.length>0){
        let accountDataList = this.accountDataList;
        accountDataList.splice(0,1);
        accountDataList.push(selected[0])
        this.$forceUpdate()
      }
    },
    accAdd (arg1,arg2) {
      let r1,r2,m;
      try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
      try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
      m=Math.pow(10,Math.max(r1,r2))
      return (arg1*m+arg2*m)/m;
    },
    accSub (arg1,arg2) {
      let r1,r2,m,n;
      try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
      try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
      m=Math.pow(10,Math.max(r1,r2));
      n=(r1>=r2)?r1:r2;
      return ((arg1*m-arg2*m)/m).toFixed(n);
    },
    relateProduct(item){
      this.service_name = item.service_name
      this.invoice_id = item.invoice_id
      this.invoice_item_id = item.item_id
      this.showProduct=true;
    },
    onSelectProduct(){
      let selected = this.$refs.relateProductSelect.getSelected();
      if(selected.length>0){
        let invoiceDataList = this.invoiceDataList;
        for(let i=0;i<invoiceDataList.length;i++){
          if(invoiceDataList[i].invoice_id == this.invoice_id){
            for(let j=0;j<invoiceDataList[i].ext.items.length;j++){
              if(invoiceDataList[i].ext.items[j].item_id == this.invoice_item_id){

                invoiceDataList[i].ext.items[j].ext.code = selected[0].code
                invoiceDataList[i].ext.items[j].ext.one_code = selected[0].one_code
                invoiceDataList[i].ext.items[j].ext.product_id = selected[0].product_id
                this.$forceUpdate()
                return
              }
            }
          }
        }
      }
    },
    checkVerifyAmount(item){
      if(item.ext.new_verify_amount && parseFloat(item.ext.new_verify_amount) <0){
        alert("本次核销金额不可小于0")
        item.ext.new_verify_amount = '';
      }
      let amount = isNaN(item.ext.new_verify_amount)||item.ext.new_verify_amount==''?0:item.ext.new_verify_amount;
      if(amount>item.ext.un_verify_amount){
        alert("本次核销金额不可大于待核销金额")
        item.ext.new_verify_amount = '';
      }else{
        item.ext.new_verify_amount = Number(amount).toFixed(2)
      }
      //次核销预付款
      let totalVerifyAmount = 0
      let totalApplyAmount = 0
      for(let i=0;i<this.purchaseOrderDataList.length;i++){
        if(this.purchaseOrderDataList[i].order_id ==item.purchase_order_id){
          let payList = this.purchaseOrderDataList[i].ext.payList
          let amountVerify = 0
          if(payList&&payList.length>0){
            for(let j=0;j<payList.length;j++){
              if(payList[j].ext.new_verify_amount != undefined && payList[j].ext.new_verify_amount != '') {
                amountVerify = this.accAdd(amountVerify, payList[j].ext.new_verify_amount)
              }
            }
          }

          this.purchaseOrderDataList[i].ext.ready_written_off_amount = amountVerify
          if(parseFloat(this.purchaseOrderDataList[i].ext.payable_amount)>0){
            if(parseFloat(this.purchaseOrderDataList[i].ext.payable_amount)>parseFloat(amountVerify)){
              this.purchaseOrderDataList[i].ext.written_off_pay_amount = amountVerify
            }else{
              this.purchaseOrderDataList[i].ext.written_off_pay_amount = this.purchaseOrderDataList[i].ext.payable_amount
            }
          }else{
            this.purchaseOrderDataList[i].ext.written_off_pay_amount = 0
          }
          this.purchaseOrderDataList[i].ext.apply_amount = this.accSub(this.purchaseOrderDataList[i].ext.payable_amount,this.purchaseOrderDataList[i].ext.written_off_pay_amount)
        }

        totalVerifyAmount = this.accAdd(totalVerifyAmount,this.purchaseOrderDataList[i].ext.written_off_pay_amount)
        totalApplyAmount = this.accAdd(totalApplyAmount,this.purchaseOrderDataList[i].ext.apply_amount)
      }
      this.totalVerifyAmount = totalVerifyAmount
      this.totalApplyAmount = totalApplyAmount

      this.$forceUpdate()

    },
    addPayment(item){
      this.purchase_order_id = item.item.order_id
      this.showPayment = true
    },
    selectInvoice(){
      this.showInvoice = true
    },
    onSelectPayment(){
      let selected = this.$refs.paymentSelect.getSelected();
      let paymentDataList = [];
      //预付款明细本次核销金额之和
      let verifiedAmount = 0;
      //本次应付金额-本次核销金额之和
      let unPayAmount = 0;
      for(let i=0;i<this.purchaseOrderDataList.length;i++){
        if(this.purchaseOrderDataList[i].order_id ==this.purchase_order_id){
          if(this.purchaseOrderDataList[i].ext.payList && this.purchaseOrderDataList[i].ext.payList.length>0){
            for(let j=0;j<this.purchaseOrderDataList[i].ext.payList.length;j++){
              paymentDataList.push(this.purchaseOrderDataList[i].ext.payList[j])
              if(this.purchaseOrderDataList[i].ext.payList[j].ext.new_verify_amount != undefined && this.purchaseOrderDataList[i].ext.payList[j].ext.new_verify_amount != ''){
                verifiedAmount = this.accAdd(verifiedAmount,this.purchaseOrderDataList[i].ext.payList[j].ext.new_verify_amount)
              }
            }
          }
          unPayAmount = this.accSub(this.purchaseOrderDataList[i].ext.payable_amount,verifiedAmount)
        }
      }
      if(parseFloat(unPayAmount)<0){
        alert("本次应付金额为负数，不可添加预付核销")
        return
      }
      for(let i=0;i<selected.length;i++){
        if(parseFloat(unPayAmount) >0){
          if(parseFloat(unPayAmount)>selected[i].ext.un_verify_amount){
            selected[i].ext.new_verify_amount = selected[i].ext.un_verify_amount
          }else{
            selected[i].ext.new_verify_amount = unPayAmount;
          }
        }else{
          selected[i].ext.new_verify_amount = 0;
        }
        unPayAmount = this.accSub(unPayAmount,selected[i].ext.new_verify_amount)
        paymentDataList.push(selected[i])
        this.selectedPaymentNo.push(selected[i]["order_no"])
      }
      let amountAdv = 0;
      let amountVerify = 0
      for(let j=0;j<paymentDataList.length;j++){
        amountAdv = this.accAdd(amountAdv,paymentDataList[j].total_payed_oa)
        if(paymentDataList[j].ext.new_verify_amount != undefined && paymentDataList[j].ext.new_verify_amount != ''){
          amountVerify = this.accAdd(amountVerify,paymentDataList[j].ext.new_verify_amount)
        }
      }
      let totalVerifyAmount = 0
      let totalApplyAmount = 0
      for(let i=0;i<this.purchaseOrderDataList.length;i++){
        if(this.purchaseOrderDataList[i].order_id ==this.purchase_order_id){
          this.purchaseOrderDataList[i].ext.advance_payment_amount = amountAdv
          this.purchaseOrderDataList[i].ext.ready_written_off_amount = amountVerify
          if(parseFloat(this.purchaseOrderDataList[i].ext.payable_amount)>0){
            if(parseFloat(this.purchaseOrderDataList[i].ext.payable_amount)>parseFloat(amountVerify)){
              this.purchaseOrderDataList[i].ext.written_off_pay_amount = amountVerify
            }else{
              this.purchaseOrderDataList[i].ext.written_off_pay_amount = this.purchaseOrderDataList[i].ext.payable_amount
            }
          }else{
            this.purchaseOrderDataList[i].ext.written_off_pay_amount = 0
          }
          this.purchaseOrderDataList[i].ext.apply_amount = this.accSub(this.purchaseOrderDataList[i].ext.payable_amount,this.purchaseOrderDataList[i].ext.written_off_pay_amount)
          this.purchaseOrderDataList[i].ext.payList = []
          let count = 1
          for(let j=0;j<paymentDataList.length;j++){
            paymentDataList[j]["no"] = count;
            count ++;
            this.purchaseOrderDataList[i].ext.payList.push(paymentDataList[j])
          }
        }
        totalVerifyAmount = this.accAdd(totalVerifyAmount,this.purchaseOrderDataList[i].ext.written_off_pay_amount)
        totalApplyAmount = this.accAdd(totalApplyAmount,this.purchaseOrderDataList[i].ext.apply_amount)
      }
      this.totalVerifyAmount = totalVerifyAmount
      this.totalApplyAmount = totalApplyAmount
      this.$forceUpdate()
    },
    deletePayment(item){
      //删除查询条件
      for(let i=0;i<this.selectedPaymentNo.length;i++){
        if(this.selectedPaymentNo[i] == item.order_no){
          this.selectedPaymentNo.splice(i, 1);
        }
      }
      //删除明细
      let purchaseOrderId;
      for(let i=0;i<this.purchaseOrderDataList.length;i++){
        if(this.purchaseOrderDataList[i].ext.payList&&this.purchaseOrderDataList[i].ext.payList.length>0){
          for(let j=0;j<this.purchaseOrderDataList[i].ext.payList.length;j++){
            if(this.purchaseOrderDataList[i].ext.payList[j].order_no == item.order_no){
              // this.purchaseOrderDataList[i].ext.payList.splice(j, 1);
              purchaseOrderId = this.purchaseOrderDataList[i].order_id
            }
          }
        }
      }
      //重新计算
      let paymentDataList = []
      for(let i=0;i<this.purchaseOrderDataList.length;i++){
        if(this.purchaseOrderDataList[i].order_id ==purchaseOrderId){
          if(this.purchaseOrderDataList[i].ext.payList&&this.purchaseOrderDataList[i].ext.payList.length>0){
            for(let j=0;j<this.purchaseOrderDataList[i].ext.payList.length;j++){
              if(this.purchaseOrderDataList[i].ext.payList[j].order_no != item.order_no){
                paymentDataList.push(this.purchaseOrderDataList[i].ext.payList[j])
              }
            }
            this.purchaseOrderDataList[i].ext.payList = []
            let count = 1
            for(let j=0;j<paymentDataList.length;j++){
              paymentDataList[j]["no"] = count;
              count ++;
              this.purchaseOrderDataList[i].ext.payList.push(paymentDataList[j])
            }
          }
        }
      }

      let totalVerifyAmount = 0
      let totalApplyAmount = 0
      for(let i=0;i<this.purchaseOrderDataList.length;i++){
        if(this.purchaseOrderDataList[i].order_id ==purchaseOrderId){
          let amountAdv = 0;
          let amountVerify = 0
          if(this.purchaseOrderDataList[i].ext.payList.length>0){
            for(let j=0;j<this.purchaseOrderDataList[i].ext.payList.length;j++){
              this.purchaseOrderDataList[i].ext.payList[j]["no"] = j+1;

              amountAdv = this.accAdd(amountAdv,this.purchaseOrderDataList[i].ext.payList[j].total_payed_oa)
              if(this.purchaseOrderDataList[i].ext.payList[j].ext.new_verify_amount != undefined && this.purchaseOrderDataList[i].ext.payList[j].ext.new_verify_amount != ''){

                amountVerify = this.accAdd(amountVerify,this.purchaseOrderDataList[i].ext.payList[j].ext.new_verify_amount)
              }
            }
          }

          this.purchaseOrderDataList[i].ext.advance_payment_amount = amountAdv
          this.purchaseOrderDataList[i].ext.ready_written_off_amount = amountVerify
          if(parseFloat(this.purchaseOrderDataList[i].ext.payable_amount)>0){
            if(parseFloat(this.purchaseOrderDataList[i].ext.payable_amount)>parseFloat(amountVerify)){
              this.purchaseOrderDataList[i].ext.written_off_pay_amount = amountVerify
            }else{
              this.purchaseOrderDataList[i].ext.written_off_pay_amount = this.purchaseOrderDataList[i].ext.payable_amount
            }
          }else{
            this.purchaseOrderDataList[i].ext.written_off_pay_amount = 0
          }
          this.purchaseOrderDataList[i].ext.apply_amount = this.accSub(this.purchaseOrderDataList[i].ext.payable_amount,this.purchaseOrderDataList[i].ext.written_off_pay_amount)
        }
        totalVerifyAmount = this.accAdd(totalVerifyAmount,this.purchaseOrderDataList[i].ext.written_off_pay_amount)
        totalApplyAmount = this.accAdd(totalApplyAmount,this.purchaseOrderDataList[i].ext.apply_amount)
      }
      this.totalVerifyAmount = totalVerifyAmount
      this.totalApplyAmount = totalApplyAmount
      this.$forceUpdate()
    },
    onSelectInvoice(){
      let selected = this.$refs.invoiceSelect.getSelected();
      if(selected.length>0){
        let invoiceType = this.select_invoice_type==null?selected[0].invoice_type:this.select_invoice_type
        for(let i=0;i<selected.length;i++){
          if(invoiceType !=selected[i].invoice_type){
            alert("请选择相同发票类型的发票")
            return;
          }
        }
        this.select_invoice_type = invoiceType;
      }else{
        return
      }
      let invoiceDataList = this.invoiceDataList;
      for(let i=0;i<selected.length;i++){
        invoiceDataList.push(selected[i])
        this.selectedInvoiceNo.push(selected[i]["invoice_no"])
      }
      let count = 1
      let invoiceTotal = 0
      let taxAmountTotal = 0
      for(let i=0;i<invoiceDataList.length;i++){
        invoiceDataList[i]["no"] = count;
        invoiceTotal = this.accAdd(invoiceTotal,invoiceDataList[i].total)
        taxAmountTotal = this.accAdd(taxAmountTotal,invoiceDataList[i].tax_amount)
        count ++;
      }
      this.total = invoiceTotal
      this.totalTaxAmount = taxAmountTotal
      let num1=0
      let num2=0
      for(let i=0;i<selected.length;i++){
        num1 = this.accAdd(num1,selected[i].total)
        num2 = this.accAdd(num2,selected[i].tax_amount)
      }
      this.totalDif = this.accSub(this.totalDif,num1)
      this.taxAmountDif = this.accSub(this.taxAmountDif,num2)
    },
    deleteInvoice(row){
      let num1 = row.item.total
      let num2 = row.item.tax_amount
      let invoiceDataList = this.invoiceDataList;
      if(confirm("确认删除吗？")){
        for(let i=0;i<this.selectedInvoiceNo.length;i++){
          if(this.selectedInvoiceNo[i] == row.item.invoice_no){
            this.selectedInvoiceNo.splice(i, 1);
          }
        }
        let index = row.index;
        this.invoiceTable.value.splice(index, 1);
        for(let i=0;i<this.invoiceTable.value.length;i++){
          this.invoiceTable.value[i].no = (i+1)
        }
        if(this.selectedInvoiceNo.length == 0){
          this.select_invoice_type=null
        }

        this.totalDif = this.accAdd(this.totalDif,num1)
        this.taxAmountDif = this.accAdd(this.taxAmountDif,num2)
        this.total = this.accSub(this.total,num1)
        this.totalTaxAmount = this.accSub(this.totalTaxAmount,num2)
        this.$forceUpdate()
      }
    },
    showAttachments: function (item) {
      this.attachmentsStr = item
      this.$refs['attachModal'].show()
    },
  },
  directives: {
    Ripple,
  },
  setup(props) {
    const toast = useToast()

    const state = reactive({
      condition: {},
    })
    // Register module
    if (!store.hasModule('purchaseInvoiceStatementStepTwo')) store.registerModule('purchaseInvoiceStatementStepTwo', purchaseInvoiceStatementStepTwoStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseInvoiceStatementStepTwo')) store.unregisterModule('purchaseInvoiceStatementStepTwo')
    })
    onMounted(() => {
      state.condition = store.getters['purchaseInvoiceStatementStepTwo/getCondition']
    })

    const onUploaded = function (id, attachment, result) {
      this.purchaseInvoiceStatement.attachments = result
    }

    const edit = function () {
      store.dispatch('purchaseInvoiceStatementStepTwo/initStepTwoPage', {id:this.statement_id,invoice_id:this.invoice_id_old}).then(res => {
        let data = res.data.data
        this.purchaseInvoiceStatement = data
        this.status_one = data.status_one
        if(this.status_one != 1 &&this.status_one != 6 &&this.status_one != 7){
          this.readonlyFlag = true
        }else{
          this.readonlyFlag = false
        }
        //保存过
        if(!data.ext.newFlag){
          this.total = data.ext.invoiceTotal
          this.totalTaxAmount = data.ext.invoiceTotalTaxAmount
          this.selectedInvoiceNo = data.ext.selectedInvoiceNo
          this.selectedPaymentNo = data.ext.selectedPaymentNo
        }
        this.taxAmountDif = isEmpty(data.tax_amount_difference)&&data.tax_amount_difference!=0?data.ext.taxAmountTax:data.tax_amount_difference
        this.totalDif = isEmpty(data.total_amount_difference)&&data.total_amount_difference!=0?data.ext.total:data.total_amount_difference
        this.settlementAmount = isEmpty(data.amount)?data.ext.settlementAmount:data.amount
        this.memo_difference = data.memo_difference
        this.totalVerifyAmount = data.verify_pre_pay
        this.totalApplyAmount = data.apply_pay
        this.last_pay_date = toDate(data.last_pay_date)
        this.pay_reason = data.pay_reason
        this.buyer_sbc_company_id = data.company_id
        this.sell_sbc_company_id = data.supplier_id
        this.supplier_name = data.supplier_name
        if(data.is_same){
          this.is_same = String(data.is_same)
        }

        let poList = data.ext.poList
        for (let i = 0; i < poList.length; i++) {
          this.purchaseOrderDataList.push(poList[i])
        }
        for (let i = 0; i < data.ext.invoiceList.length; i++) {
          this.invoiceDataList.push(data.ext.invoiceList[i])
        }
        //账号信息
        let account = data.ext.account;
        if(account){
          this.accountDataList.push(account)
        }

        let totalVerifyAmount = 0
        let totalApplyAmount = 0
        for(let i=0;i<this.purchaseOrderDataList.length;i++){
          totalVerifyAmount = this.accAdd(totalVerifyAmount,this.purchaseOrderDataList[i].ext.written_off_pay_amount)
          totalApplyAmount = this.accAdd(totalApplyAmount,this.purchaseOrderDataList[i].ext.apply_amount)
        }
        this.totalVerifyAmount = totalVerifyAmount
        this.totalApplyAmount = totalApplyAmount
        this.$forceUpdate()
      })
    }

    const invoiceTable = ref(null)
    const purchaseOrderTable = ref(null)
    const accountTable = ref(null)
    const {
      invoiceTableColumns,
      purchaseOrderTableColumns,
      accountTableColumns,

    } = purchaseInvoiceStatementStepTwo(
        {}
    )

    return {
      invoiceTableColumns,
      purchaseOrderTableColumns,
      accountTableColumns,
      invoiceTable,
      purchaseOrderTable,
      accountTable,

      onUploaded,
      edit,

      // Filter
      avatarText,
      getCodeLabel,
      getCode,
      toDate,
      getCodeColor,
      getCodeOptions,
      isEmpty,
    }
  },
  created() {
    this.statement_id = this.$route.query.statement_id || 0;
    this.invoice_id_old = this.$route.query.invoice_id || 0;
    this.edit();
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
