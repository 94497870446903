import {ref, watch, computed, reactive} from '@vue/composition-api'
import store from '@/store'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function invoiceSelectList(config) {
  const toast = useToast()
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: '#', label: '#' },
    { key: 'invoice_type', label: '发票类型' },
    { key: 'buyer_name', label: '购买方名称' },
    { key: 'buyer_idno', label: '购买方税号' },
    { key: 'sell_name', label: '销售方名称' },
    { key: 'sell_idno', label: '销售方税号' },
    { key: 'total', label: '价税合计' },
    { key: 'make_date_val', label: '开票日期' },
    { key: 'invoice_code', label: '发票代码' },
    { key: 'invoice_no', label: '发票号码' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const orderBy = ref(" a.create_time desc ")
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const searchList = (ctx, callback) => {
    const condition = {...store.getters['invoiceSelectList/getCondition']}
    console.log("fffffffff")
    console.log(config)
    console.log(config.selectedInvoiceNo)
    store
        .dispatch('invoiceSelectList/invoiceSelect', {
          invoice_no:condition.invoice_no,
          sell_sbc_company_id:config.sell_sbc_company_id,
          buyer_sbc_company_id:config.buyer_sbc_company_id,
          selectedInvoiceNo:config.selectedInvoiceNo==undefined?null:config.selectedInvoiceNo.join(","),
          start: start.value,
          limit: limit.value,
          order_by: orderBy.value,
          order_desc: isSortDirDesc.value === true ? 'desc':'',
        })
        .then(response => {
          const data = response.data.data
          const list = data.ext.list
          listTotals.value = data.ext.totals

          callback(list)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '发票列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })

  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    orderBy,
    isSortDirDesc,
    refListTable,
  }
}
