<template>
  <div>
    <b-card
        no-body
        class="mb-0 list-table"
    >
<!--      <div>
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-card>
              <b-row>
                &lt;!&ndash;筛选条件&ndash;&gt;
                <b-col
                    cols="6"
                    md="3"
                >
                  <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="发票号码:"
                      label-for="invoice_no"
                      label-size="sm"
                  >
                    <b-form-input
                        id="name"
                        v-model="condition.invoice_no"
                        class="d-inline-block mr-1"
                        size="sm"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                    cols="12"
                    md="12"
                    class=" demo-inline-spacing d-flex align-items-center justify-content-end"
                >
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      type="submit"
                  >
                    <span class="align-right">查询</span>
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      type="reset"
                  >
                    <span class="align-right">重置</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-form>
      </div>-->
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="order_id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >

        <template v-slot:head(#)="data">
          <b-form-checkbox v-model="allchecked" @change="toggleAll" :indeterminate="indeterminate">
          </b-form-checkbox>
        </template>

        <template v-slot:cell(#)="row">
          <b-form-checkbox @change="checkboxClick(row.item,$event)" v-model="isCheckedObj['index'+row.item.order_id]">
          </b-form-checkbox>
        </template>

        <!-- Columns -->
        <template #cell(order_no)="data">
<!--          <b-form-checkbox name="check-button"
                           button-variant="default"
                           size="sm"
                           button>
          </b-form-checkbox>-->
          {{data.item.order_no}}
        </template>
        <template #cell(purchase_order_no)="data">
          {{ data.item.purchase_order_no }}
        </template>
        <template #cell(total_payed_oa)="data">
          {{ data.item.total_payed_oa }}
        </template>
        <template #cell(un_verify_amount)="data">
          {{ data.item.ext.un_verify_amount }}
        </template>
        <template #cell(create_time)="data">
          {{ toDate(data.item.create_time) }}
        </template>
        <template #cell(applier_id)="data">
          {{getCodeLabel('user', data.item.applier_id)}}
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import Ripple from "vue-ripple-directive";
import {ref, onUnmounted, reactive, onMounted, toRefs} from '@vue/composition-api'
import { avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions,toDate } from '@core/utils/filter'
import paymentSelectList from './paymentSelectList'
import paymentSelectStore from "@/views/apps/purchaseinvoicestatement/paymentSelectStore";

export default {
  components: {
    BCard,
    BModal,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      indeterminate: false,
      allchecked: false,
      isCheckedObj: {},
      selected: [],
    }
  },
  watch: {
    start:'refetchData',
    limit:'refetchData'
  },
  props:{
    purchase_order_id: {
      type: Number
    },
    selected_no: {
      type: Array,
      default: () => []
    },
    statement_id: {
      type: String
    },
  },

  methods: {
    getSelected: function () {
      return this.selected
    },
    /*searchByCondition(){
      this.refetchData()
    },
    resetCondition() {
      this.state.condition = {}
      store.commit('paymentSelectList/updateCondition', this.state.condition)
      this.refetchData()
    },*/
    // 清空选择框
    clearCheckBox() {
      this.allchecked = false;
      this.indeterminate = false
      this.selected = []
      this.$forceUpdate()
    },
    refetchData(){
      this.$refs.refListTable.refresh()
    },
    // 点击全选按钮
    toggleAll(checked) {
      const arrSelected = [];
      if(this.selected&&this.selected.length>0){
        this.selected.forEach(element => {
          arrSelected.push(element.order_id)
        });
      }
      this.table.forEach(element => {
        this.isCheckedObj['index' + element.order_id] = checked;
        let indexOf = arrSelected.indexOf(element.order_id);
        if(indexOf >-1&&!checked){
          arrSelected.splice(indexOf, 1);
          this.selected.splice(indexOf, 1);
        }else if(indexOf < 0 && checked){
          arrSelected.push(element.order_id);
          this.selected.push(element);
        }
      });
      this.$forceUpdate()
    },
    // 复选框点击选中与取消
    checkboxClick(item,checked) {
      //全选框
      let allselect = true;
      this.table.forEach(element => {
        if (this.isCheckedObj['index' + element.order_id] !== true) {
          allselect = false;
        }
      });
      this.allchecked = allselect;
      if(checked){
        this.selected.push(item)
      }else{
        for(let i=0;i<this.selected.length;i++){
          if(this.selected[i].order_id == item.order_id){
            this.selected.splice(i, 1);
          }
        }
      }
      this.$forceUpdate()
    },
  },
  directives: {
    Ripple,
  },
  setup(props) {

    const state = reactive({
      condition: {},
    })

    // Register module
    if (!store.hasModule('paymentSelectList')) store.registerModule('paymentSelectList', paymentSelectStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('paymentSelectList')) store.unregisterModule('paymentSelectList')
    })

    onMounted(() => {
      state.condition = store.getters['paymentSelectList/getCondition']
    })

    const {
      searchList,
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
    } = paymentSelectList(
        {purchase_order_id:props.purchase_order_id
          ,selectedPaymentNo:props.selected_no
          ,statement_id:props.statement_id
        }
    )

    return {
      ...toRefs(state),
      state,
      searchList,
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,

      // Filter
      avatarText,
      getCodeLabel,
      getCode,
      getCodeColor,
      getCodeOptions,
      toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
